import settings from './settings'
import { createSelector } from 'reselect'

import {
  texturesSelector,
  posicionesSelector,
  setTexture
} from './reducerTextures'
import { searchId } from './utils'

import * as THREE from 'three'

export const SET_TEXTURES = 'SET_TEXTURES'
export const CHANGE_CUBE_MODEL = 'CHANGE_CUBE_MODEL'
export const SET_CHANGES = 'SET_CHANGES'

const initialState = {
  numChangesCubes: 0,
  numChangesCapas: 0,
  idActualCube: settings['initial cube'],
  idAntCube: settings['initial cube'],
  textureActual: 'p2_a1_op1',
  textureAnt: 'p2_a1_op1',
  clase: ''
}

export const setTextures = (idTexture, idTextureAnt, clase) => ({
  type: SET_TEXTURES,
  idTexture,
  idTextureAnt,
  clase
})

export const changeCubeModel = (
  idActualCube,
  idAntCube,
  idTexture,
  idTextureAnt,
  clase
) => ({
  type: CHANGE_CUBE_MODEL,
  idActualCube,
  idAntCube,
  idTexture,
  idTextureAnt,
  clase
})

const checkImage = ({ id, src }) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({ id, img })
    }
    img.src = src
  })

const textureLoader1 = new THREE.CubeTextureLoader()
const textureLoader2 = new THREE.CubeTextureLoader()

const checkImage1 = ({ id, urls, loader }) => {
  return new Promise((resolve) => {
    loader.load(urls, (texture) => {
      resolve({ id, texture })
    })
  })
}

let wait = null
const promiseTime = new Promise((resolve, reject) => {
  wait = setTimeout(() => {
    clearTimeout(wait)
    resolve('Promise A win!')
  }, 200)
})

const loadImage = (dispatch, state, idTexture, idTextureAnt, resolve) => {
  const texture = searchId(texturesSelector(state), idTexture)
  const textureAnt = searchId(texturesSelector(state), idTextureAnt)

  const promises = []

  if (texture === null) {
    //const pathTexture = `images/${idTexture}.jpg`
    promises.push(
      checkImage1({
        id: idTexture,
        urls: [
          `images/${idTexture}_px.jpg`,
          `images/${idTexture}_nx.jpg`,
          `images/${idTexture}_py.jpg`,
          `images/${idTexture}_ny.jpg`,
          `images/${idTexture}_pz.jpg`,
          `images/${idTexture}_nz.jpg`
        ],
        loader: textureLoader1
      })
    )
  }

  if (textureAnt === null) {
    //const pathTexture = `images/${idTextureAnt}.jpg`
    promises.push(
      checkImage1({
        id: idTextureAnt,
        urls: [
          `images/${idTextureAnt}_px.jpg`,
          `images/${idTextureAnt}_nx.jpg`,
          `images/${idTextureAnt}_py.jpg`,
          `images/${idTextureAnt}_ny.jpg`,
          `images/${idTextureAnt}_pz.jpg`,
          `images/${idTextureAnt}_nz.jpg`
        ],
        loader: textureLoader2
      })
    )
    //promises.push(checkImage({ id: idTextureAnt, src: pathTexture }))
  }

  if (promises.length > 0) {
    promiseTime.then((s) => {
      console.log(s)
    })

    Promise.all(promises).then((imgs) => {
      imgs.forEach(({ id, texture }) => {
        //dispatch(setTexture(idTexture, img))
        dispatch(setTexture(idTexture, texture))
        clearTimeout(wait)
      })

      resolve()
    })
  } else {
    resolve()
  }
}

/*
const loadImage = (dispatch, state, idTexture, idTextureAnt, resolve) => {
  const texture = searchId(texturesSelector(state), idTexture)
  const textureAnt = searchId(texturesSelector(state), idTextureAnt)

  const promises = []

  if (texture === null) {
    const pathTexture = `images/${idTexture}.jpg`
    promises.push(checkImage({ id: idTexture, src: pathTexture }))
  }

  if (textureAnt === null) {
    const pathTexture = `images/${idTextureAnt}.jpg`
    promises.push(checkImage({ id: idTextureAnt, src: pathTexture }))
  }

  if (promises.length > 0) {
    promiseTime.then((s) => {
      console.log(s)
    })

    Promise.all(promises).then((imgs) => {
      imgs.forEach(({ id, img }) => {
        dispatch(setTexture(idTexture, img))
        clearTimeout(wait)
      })

      resolve()
    })
  } else {
    resolve()
  }
}
*/

const idImagePosicion = (posicion, idCube, opcionesSeleccionadas, nocturno) => {
  if (nocturno) {
    return `nocturno/${idCube}_nocturno`
  } else {
    const opcionesSeleccionadas1 = JSON.parse(opcionesSeleccionadas)

    return posicion.acabados.reduce((result, idAcabado) => {
      return `${result}_${idAcabado}_${searchId(
        opcionesSeleccionadas1,
        idAcabado
      ).opciones.join('_')}`
    }, idCube)
  }
}

const idImageState = (state, { cube, opcionesSeleccionadas, nocturno }) => {
  const posicion = searchId(posicionesSelector(state), cube)

  return idImagePosicion(posicion, cube, opcionesSeleccionadas, nocturno)
}

export const changeCubeModelOpcion = ([ant, actual]) => {
  return (dispatch, getState) => {
    const state = getState()

    console.log(ant)
    console.log(actual)

    const idTexture = idImageState(state, actual)
    const idTextureAnt = idImageState(state, ant)

    console.log(idTexture)
    console.log(idTextureAnt)

    if (actual.clase === 'cubes') {
      loadImage(dispatch, state, idTexture, idTextureAnt, () => {
        dispatch(
          changeCubeModel(
            actual.cube,
            ant.cube,
            idTexture,
            idTextureAnt,
            actual.clase
          )
        )
      })
    } else {
      loadImage(dispatch, state, idTexture, idTextureAnt, () => {
        dispatch(setTextures(idTexture, idTextureAnt, actual.clase))
      })
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEXTURES:
      console.log(action)
      return {
        ...state,
        numChangesCapas: state.numChangesCapas + 1,
        textureActual: action.idTexture,
        textureAnt: action.idTextureAnt,
        clase: action.clase
      }
    case CHANGE_CUBE_MODEL:
      console.log(action)
      return action.idActualCube === state.idActualCube
        ? state
        : {
            ...state,
            idAntCube: action.idAntCube,
            idActualCube: action.idActualCube,
            numChangesCubes: state.numChangesCubes + 1,
            numChangesCapas:
              (state.numChangesCubes + 1) % 2 !== state.numChangesCapas % 2
                ? state.numChangesCapas + 1
                : state.numChangesCapas,
            textureActual: action.idTexture,
            textureAnt: action.idTextureAnt,
            clase: action.clase
          }
    default:
      return state
  }
}

export const claseSelector = (state) => state.changes.clase
export const idActualCubeSelector = (state) => state.changes.idActualCube
export const idAntCubeSelector = (state) => state.changes.idAntCube
const textureActualSelector = (state) => state.changes.textureActual
const textureAntSelector = (state) => state.changes.textureAnt

export const getActualTexture = createSelector(
  [texturesSelector, textureActualSelector],
  (textures, textureActual) => searchId(textures, textureActual)
)

export const getAntTexture = createSelector(
  [texturesSelector, textureAntSelector],
  (textures, textureAnt) => searchId(textures, textureAnt)
)

/*export const getActualTexture = createSelector(
  [
    posicionesSelector,
    opcionesSeleccionadasPostSelector,
    idActualCubeSelector,
    texturesSelector
  ],
  (posiciones, opcionesSeleccionadas, idActualCube, textures) => {
    const posicion = searchId(posiciones, idActualCube)
    const idImage = idImagePosicion(
      posicion,
      idActualCube,
      opcionesSeleccionadas
    )

    return searchId(textures, idImage)
  }
)*/

/*export const getAntTexture = createSelector(
  [
    posicionesSelector,
    opcionesSeleccionadasPostSelector,
    opcionesSeleccionadasAntSelector,
    idAntCubeSelector,
    idActualCubeSelector,
    texturesSelector,
    claseSelector
  ],
  (
    posiciones,
    opcionesSeleccionadas,
    opcionesSeleccionadasAnt,
    idAntCube,
    idActualCube,
    textures,
    clase
  ) => {
    let idImage = ''

    if (clase === 'cubes') {
      const posicion = searchId(posiciones, idAntCube)
      idImage = idImagePosicion(posicion, idAntCube, opcionesSeleccionadas)
    } else {
      const posicion = searchId(posiciones, idActualCube)
      idImage = idImagePosicion(
        posicion,
        idActualCube,
        opcionesSeleccionadasAnt
      )
    }
    return searchId(textures, idImage)
  }
)*/

export const getAcabados = createSelector(
  [posicionesSelector, idActualCubeSelector],
  (posiciones, idActualCube) => searchId(posiciones, idActualCube).acabados
)
