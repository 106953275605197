import settings from './settings'
import { searchId, updateId } from './utils'
import { createSelector } from 'reselect'
import { number_format } from './number_format'

import { CubeTexture, RGBAFormat, LinearFilter } from 'three'

export const SET_TEXTURE = 'SET_TEXTURE'
export const SET_OPCION_SELECCIONADA = 'SET_OPCION_SELECCIONADA'
export const SET_DEFAULTS = 'SET_DEFAULTS'

const initialState = {
  imagesVisible: [{ id: 'p2_a1_op1', src: `images/p2_a1_op1.jpg` }],
  textures: [],
  opcionesSeleccionadas: JSON.stringify(settings.opcionesSeleccionadas),
  posiciones: settings.positions.map((position) => ({
    id: position.id,
    acabados: position.acabados
  })),
  acabados: settings.acabados.map((acabado) => ({
    id: acabado.id,
    title: acabado.title,
    opciones: acabado.opciones
  })),
  opciones: settings.opciones.map((opcion) => ({
    id: opcion.id,
    title: opcion.title,
    precio: opcion.precio,
    srcIcono: opcion.srcIcono
  })),
  precioBase: settings.precioBase
}

export const setTexture = (id, img) => ({
  type: SET_TEXTURE,
  id,
  img
})

export const setOpcionSeleccionada = (idAcabado, idOpcion) => ({
  type: SET_OPCION_SELECCIONADA,
  idAcabado,
  idOpcion
})

export const setDefaults = () => ({
  type: SET_DEFAULTS
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEXTURE:
      if (searchId(state.textures, action.id) === null) {
        //const texture = getTextureFromAtlasFile2(action.img)
        const texture = action.img
        texture.format = RGBAFormat
        texture.minFilter = LinearFilter
        texture.needsUpdate = true

        return {
          ...state,
          textures: [...state.textures, { id: action.id, texture }]
        }
      } else {
        return state
      }
    /*
      case SET_TEXTURE:
        if (searchId(state.textures, action.id) === null) {
          const texture = getTextureFromAtlasFile2(action.img)

          return {
            ...state,
            textures: [...state.textures, { id: action.id, texture }]
          }
        } else {
          return state
        }*/
    case SET_OPCION_SELECCIONADA:
      const opcionesSeleccionadas = JSON.parse(state.opcionesSeleccionadas)

      return {
        ...state,
        opcionesSeleccionadas: JSON.stringify(
          updateId(opcionesSeleccionadas, action.idAcabado, 'opciones', [
            action.idOpcion
          ])
        )
      }
    case SET_DEFAULTS:
      return {
        ...state,
        opcionesSeleccionadas: JSON.stringify(settings.opcionesSeleccionadas)
      }
    default:
      return state
  }
}

const imagesVisibleSelector = (state) => state.textures.imagesVisible
export const texturesSelector = (state) => state.textures.textures
export const opcionesSeleccionadasSelector = (state) =>
  state.textures.opcionesSeleccionadas

export const posicionesSelector = (state) => state.textures.posiciones
const acabadosSelector = (state) => state.textures.acabados
const opcionesSelector = (state) => state.textures.opciones
const precioBaseSelector = (state) => state.textures.precioBase

export const getImagesVisible = createSelector(
  [imagesVisibleSelector],
  (imagesVisible) => imagesVisible
)

export const getAcabado = () =>
  createSelector(
    [
      acabadosSelector,
      opcionesSeleccionadasSelector,
      opcionesSelector,
      (_, idAcabado) => idAcabado
    ],
    (acabados, opcionesSeleccionadas, opciones, idAcabado) => {
      const acabado = searchId(acabados, idAcabado)
      console.log(idAcabado)
      return {
        ...acabado,
        id: acabado.id,
        title: acabado.title,
        opciones: acabado.opciones,
        opcionesSeleccionadas: searchId(
          JSON.parse(opcionesSeleccionadas),
          acabado.id
        ).opciones
      }
    }
  )

export const getOpcion = () =>
  createSelector(
    [opcionesSelector, (_, idOpcion) => idOpcion],
    (opciones, idOpcion) => {
      const opcion = searchId(opciones, idOpcion)

      return {
        title: opcion.title,
        subtitle: opcion.subtitle,
        srcIcono: opcion.srcIcono,
        precio: number_format(opcion.precio, 2, ',', '.')
      }
    }
  )

const getSubtotalAcabado = (idAcabado, opciones, opcionesSeleccionadas) => {
  const opcionesSeleccionadas1 = JSON.parse(opcionesSeleccionadas)

  return searchId(opcionesSeleccionadas1, idAcabado).opciones.reduce(
    (result, idOpcion) => {
      const { precio } = searchId(opciones, idOpcion)
      return result + precio
    },
    0
  )
}

export const getTotal = createSelector(
  [
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    precioBaseSelector
  ],
  (acabados, opciones, opcionesSeleccionadas, precioBase) => {
    const precio = acabados.reduce(
      (result, acabado) =>
        result +
        getSubtotalAcabado(acabado.id, opciones, opcionesSeleccionadas),
      precioBase
    )

    return number_format(precio, 2, ',', '.')
  }
)

const getTextureFromAtlasFile2 = (imageObj) => {
  const texture = new CubeTexture()

  let canvas
  let context

  //Invertir
  const canvasImg = document.createElement('canvas')
  context = canvasImg.getContext('2d')
  canvasImg.height = imageObj.height
  canvasImg.width = imageObj.width

  context.translate(canvasImg.width, 0)
  context.scale(-1, 1)
  context.drawImage(imageObj, 0, 0)
  context.setTransform(1, 0, 0, 1, 0, 0)

  const tileWidth = imageObj.width / 6
  //px nx py ny pz nz
  //px
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 4,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[0] = canvas

  //nx
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 5,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[1] = canvas

  //py
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 3,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[2] = canvas

  //ny
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 2,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[3] = canvas

  //pz
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[4] = canvas

  //nz
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    0,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[5] = canvas

  texture.format = RGBAFormat
  texture.minFilter = LinearFilter
  texture.needsUpdate = true

  return texture
}
