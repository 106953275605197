import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import { getEndMenu, setPositionY } from '../../modules/reducerDimensions'

const useStyles = createUseStyles({
  slider: { height: '100%', display: (props) => props.display },
  menuViewport: {
    width: '100%',
    paddingTop: '1rem'
  }
})

const MenuViewport = ({ children }) => {
  const endMenu = useSelector(getEndMenu)
  const showSlider = endMenu < 0

  const dispatch = useDispatch()

  let ocultSlider = false

  let display = 'block'

  if (ocultSlider) {
    display = 'none'
    dispatch(setPositionY(0))
  } else if (showSlider) {
    display = 'block'
  } else {
    display = 'none'
    dispatch(setPositionY(0))
  }

  const classes = useStyles({ display })

  return <div className={classes.menuViewport}>{children}</div>
}

export default MenuViewport
