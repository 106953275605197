import { Vector3 } from 'three'
import { createSelector } from 'reselect'
import { searchId } from './utils'

export const SET_POSITION_HOTSPOT = 'SET_POSITION_HOTSPOT'

const initialState = {
  hotspots3d: [
    {
      id: 'h1',
      type: 'point',
      points: ['p1']
    }
  ],
  points: [
    {
      id: 'p1',
      position: new Vector3(
        452.1696941266577,
        149.9444634750086,
        -1113.034282863109
      )
    }
  ],
  points2d: []
}

export const setPositionHotspot = positions => ({
  type: SET_POSITION_HOTSPOT,
  positions
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POSITION_HOTSPOT:
      if (action.positions) {
        const points2d = [...state.points2d]

        action.positions.forEach(position => {
          let esta = false
          for (let i = 0, iLen = points2d.length; i < iLen; i++) {
            if (position.id === points2d[i].id) {
              points2d[i] = position
              esta = true
            }
          }

          if (!esta) {
            points2d.push(position)
          }
        })

        return {
          ...state,
          points2d
        }
      } else {
        return state
      }
    default:
      return state
  }
}

const hotspots3dSelector = state => state.hotspots.hotspots3d
const pointsSelector = state => state.hotspots.points
const points2dSelector = state => state.hotspots.points2d

export const getIdHotspots = createSelector(
  [hotspots3dSelector],
  hotspots => hotspots.map(hotspot => hotspot.id)
)

export const getHotspotsPoints = createSelector(
  [hotspots3dSelector, pointsSelector],
  (hotspots3d, points) =>
    hotspots3d.map(hotspot3d => ({
      id: hotspot3d.id,
      points: hotspot3d.points.map(idPoint => searchId(points, idPoint))
    }))
)

export const getHotspot = () =>
  createSelector(
    [hotspots3dSelector, points2dSelector, (_, idHotspot) => idHotspot],
    (hotspots3d, points2d, idHotspot) => {
      const hotspot3d = searchId(hotspots3d, idHotspot)

      const points = hotspot3d.points.map(idPoint3d =>
        searchId(points2d, idPoint3d)
      )

      return {
        id: hotspot3d.id,
        type: hotspot3d.type,
        points,
        tooltip: 'hola'
      }
    }
  )
