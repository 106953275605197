import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getActualCube, getAntCube } from '../../modules/reducer'
import {
  getActualTexture,
  getAntTexture,
  claseSelector
} from '../../modules/reducerChanges'
import {
  setPositionHotspot,
  getHotspotsPoints
} from '../../modules/reducerHotspots'

import { Canvas } from 'react-three-fiber'
import { useSpring } from 'react-spring/three'
import * as easings from 'd3-ease'

import CameraMove from './Camera/CameraMove'
import CaptureImage from './CaptureImage'
import Model from './Geometries/ModelShader'
import HelperNormals from './Geometries/HelperNormals'
import SkyBox from './Geometries/SkyBox'
import CalculatePositions from './CalculatePositions'

const Scene = () => {
  const url = useSelector(state => state.reducer.model)

  const actualCube = useSelector(getActualCube)
  const antCube = useSelector(getAntCube)

  const actualTexture = useSelector(getActualTexture)
  const antTexture = useSelector(getAntTexture)

  const numChangesCubes = useSelector(state => state.changes.numChangesCubes)
  const numChangesCapas = useSelector(state => state.changes.numChangesCapas)

  const clase = useSelector(claseSelector)

  //const hotspotsPoints = useSelector(getHotspotsPoints)

  console.log(actualCube)
  console.log(antCube)
  console.log(actualTexture)
  console.log(antTexture)
  console.log(numChangesCubes)
  console.log(numChangesCapas)
  console.log(clase)

  const { progress } = useSpring({
    progress: numChangesCubes % 2 === 0 ? 1 : 0,
    config: { duration: 1000, easing: easings.easeCubicInOut }
  })

  //const dispatch = useDispatch()
  /*const setPositions = useCallback(
    positions => dispatch(setPositionHotspot(positions)),
    [dispatch]
  )*/

  return (
    <>
      <a
        id="capture-image"
        href="http://www.google.es"
        style={{ display: 'none' }}
      >
        a
      </a>
      <Canvas gl={{ preserveDrawingBuffer: true, antialias: true }}>
        <CameraMove
          progress={progress}
          numChanges={numChangesCubes}
          actualCube={actualCube.cube.position}
          antCube={antCube.cube.position}
        />
        <Model
          url={url}
          antTexture={antTexture.texture}
          antPosition={antCube.cube.position}
          actualTexture={actualTexture.texture}
          actualPosition={actualCube.cube.position}
          progress={progress}
          numChanges={numChangesCubes}
        />
        <HelperNormals />
        <SkyBox
          sizeSkyBox={1500}
          actualPosition={actualCube.cube.position}
          antTexture={antTexture.texture}
          actualTexture={actualTexture.texture}
          numChangesCapas={numChangesCapas}
          progressCubes={progress}
          numChangesCubes={numChangesCubes}
          clase={clase}
        />
        <CaptureImage idEnlace="capture-image" />
      </Canvas>
    </>
  )
}
/*
<CalculatePositions
  hotspots={hotspotsPoints}
  setPositions={setPositions}
/>
*/
export default React.memo(Scene)
