import React from 'react'

import { useSelector } from 'react-redux'

import { createUseStyles } from 'react-jss'

//import ReactTooltip from 'react-tooltip'

const useStyles = createUseStyles({
  hotspot: {
    position: 'absolute',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
    '-webkit-tap-highlight-color': 'transparent'
  },
  tooltip: {
    borderRadius: 0,
    backgroundColor: 'rgba(255,255,255,0.75)!important',
    userSelect: 'none!important'
  }
})

const HotspotArea = ({ points, tooltip, onClick }) => {
  const isClick = useSelector(state => !state.rotate.dragging)

  if (points[0] !== null) {
    if (points[0].left === -20000 || points[0].top === -20000) {
      points[0] = null
    }
  }

  if (points[1] !== null) {
    if (points[1].left === -20000 || points[1].top === -20000) {
      points[1] = null
    }
  }

  const width =
    points[0] !== null && points[1] !== null
      ? points[1].left - points[0].left
      : null

  const height =
    points[0] !== null && points[1] !== null
      ? points[1].top - points[0].top
      : null

  const onClickComprobacion = e => {
    if (isClick) onClick()
  }

  const classes = useStyles()

  /*return points[0] === null || points[1] === null ? null : (
    <>
      <ReactTooltip type="light" />
      <div
        data-tip={tooltip}
        data-place="bottom"
        data-class={classes.tooltip}
        data-arrow-color="transparent"
        className={classes.hotspot}
        draggable={false}
        style={{
          top: points[0].top,
          left: points[0].left,
          width: isNaN(width) ? 0 : width,
          height: isNaN(height) ? 0 : height
        }}
        onClick={onClickComprobacion}
      />
    </>
)*/

  return points[0] === null || points[1] === null ? null : (
    <div
      data-tip={tooltip}
      data-place="bottom"
      data-class={classes.tooltip}
      data-arrow-color="transparent"
      className={classes.hotspot}
      draggable={false}
      style={{
        top: points[0].top,
        left: points[0].left,
        width: isNaN(width) ? 0 : width,
        height: isNaN(height) ? 0 : height
      }}
      onClick={onClickComprobacion}
    />
  )
}

export default HotspotArea
