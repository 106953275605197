export const TOGGLE_NOCTURNO = 'TOGGLE_NOCTURNO'

const initialState = {
  nocturno: false
}

export const toggleNocturno = () => ({
  type: TOGGLE_NOCTURNO
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NOCTURNO:
      return {
        ...state,
        nocturno: !state.nocturno
      }
    default:
      return state
  }
}

export const nocturnoSelector = (state) => state.nocturno.nocturno
