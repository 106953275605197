import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAcabado } from '../../modules/reducerTextures'

import Tree from './Tree'
import Frame from './Frame'

const Acabado = ({ id, padding }) => {
  const getA = useMemo(getAcabado, [])

  const acabado = useSelector((state) => getA(state, id))
  const title = acabado.title
  const opciones = acabado.opciones
  const opcionesSeleccionadas = acabado.opcionesSeleccionadas

  return (
    <Tree name={title} padding={padding} open={true}>
      {opciones.map((idOpcion) => (
        <Frame
          key={idOpcion}
          id={idOpcion}
          idAcabado={id}
          padding={padding}
          visible={opcionesSeleccionadas.indexOf(idOpcion) !== -1}
        />
      ))}
    </Tree>
  )
}

export default Acabado
