import React, { useRef, useEffect, useState } from 'react'

import * as THREE from 'three'
import { useUpdate } from 'react-three-fiber'

import useObservable from '../useObservable'
import { moveModelHelper$ } from '../../../modules/observables'

const HelperNormals = () => {
  const normal = useRef(new THREE.Vector3())

  const intersect = useObservable(moveModelHelper$, null)

  const ref = useUpdate(
    (helper) => {
      if (intersect) {
        helper.position.set(0, 0, 0)
        normal.current
          .copy(intersect.normal)
          .applyMatrix3(intersect.normalMatrix)
        helper.lookAt(normal.current)
        helper.rotateX(Math.PI / 2)
        helper.position.copy(intersect.point)
      }
    },
    [intersect]
  )

  const [img, setImg] = useState(null)

  useEffect(() => {
    new THREE.TextureLoader().load(
      'images/iconos/image_3.png',
      (object) => {
        setImg(object)
      },
      undefined,
      (xhr) => console.log(xhr)
    )
  }, [])

  return img === null ? null : (
    <mesh ref={ref} renderOrder={10}>
      <cylinderBufferGeometry attach="geometry" args={[10, 10, 0.25, 32]} />
      <meshBasicMaterial attach="material" map={img} />
    </mesh>
  )
}
/*
<texture
  attach="map"
  image={img}
  onUpdate={(self) => img && (self.needsUpdate = true)}
/>
*/
//<meshNormalMaterial attach="material" />
export default HelperNormals
