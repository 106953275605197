import React, { useCallback } from 'react'
import './App.css'

import { useSelector, useDispatch } from 'react-redux'
import { setFull } from './modules/reducer'

import { ThemeProvider } from 'react-jss'
import Theme from './Theme'

import Fullscreen from 'react-full-screen'

//import BotonesSeleccion from './Components/2d/BotonesSeleccion'

import AppBarCubes from './Components/2d/AppBarCubes'
import ThreeContainer from './Components/2d/ThreeContainer'
import MenuEstancias from './Components/MenuEstancias/MenuEstancias'
import Nocturno from './Components/Nocturno/Nocturno'
//import IconLoader from './Components/2d/IconLoader'

import Generador from './Components/Generador/Generador6'

function App() {
  const dispatch = useDispatch()
  const isFull = useSelector((state) => state.reducer.isFull)
  const onChange = useCallback((isFull) => dispatch(setFull(isFull)), [
    dispatch
  ])

  return (
    <ThemeProvider theme={Theme}>
      <Fullscreen enabled={isFull} onChange={onChange}>
        <div className="App">
          <ThreeContainer />
          <AppBarCubes />
          <MenuEstancias />
          <Nocturno />
        </div>
      </Fullscreen>
    </ThemeProvider>
  )
  /*
<div style={{ position: 'absolute', top: '50%', left: '50%' }}>
  <IconLoader size="10em" />
</div>

*/
/*  return (
    <div className="App">
      <Generador />
    </div>
  )*/
}

export default App
