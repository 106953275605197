import { useEffect } from 'react'
import { useThree } from 'react-three-fiber'

import store from '../../store'
import { setCaptureImage } from '../../modules/reducer'

import { captureImage$ } from '../../modules/observables'

const CaptureImage = ({ idEnlace }) => {
  const { canvas } = useThree()

  useEffect(() => {
    const subscription = captureImage$.subscribe(newValue => {
      console.log(newValue)

      store.dispatch(setCaptureImage(false))

      const enlace = document.getElementById(idEnlace)

      const strMime = 'image/png'
      const data = canvas.toDataURL(strMime)

      const strDownloadMime = 'image/octet-stream'

      const imgData = data.replace(strMime, strDownloadMime)

      enlace.href = imgData
      enlace.target = '_blank'
      enlace.download = 'image.png'
      enlace.click()
    })
    return () => subscription.unsubscribe()
  }, [canvas, idEnlace])

  return null
}

export default CaptureImage
