import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import IconLoadingNumber from './IconLoadingNumber'

import * as THREE from 'three'

import {
  setImageLoaded,
  setImagesLoading,
  getImagesNoLoaded
} from '../../modules/reducerLoadImages'

const ImageLoader = ({ resolveImage }) => {
  const dispatch = useDispatch()

  const imagesNoLoaded = useSelector(getImagesNoLoaded)
  const imagesLoading = useSelector((state) => state.loadImages.imagesLoading)

  useEffect(() => {
    const checkImage = (image) =>
      new Promise((resolve) => {
        new THREE.CubeTextureLoader().load(
          [
            `images/${image.id}_px.jpg`,
            `images/${image.id}_nx.jpg`,
            `images/${image.id}_py.jpg`,
            `images/${image.id}_ny.jpg`,
            `images/${image.id}_pz.jpg`,
            `images/${image.id}_nz.jpg`
          ],
          (img) => {
            console.log('hola')
            resolve({ image, img })
          }
        )
      })

    /*
      const checkImage = image =>
        new Promise(resolve => {
          const img = new Image()
          img.onload = () => {
            resolve({ image, img })
          }
          img.src = image.src
        })
      */

    if (imagesNoLoaded.length > 0) {
      const newImagesLoading = imagesNoLoaded.reduce((result, image) => {
        if (!imagesLoading.some((im) => im.id === image.id)) {
          result.push(image)
        }
        return result
      }, [])

      dispatch(setImagesLoading(newImagesLoading))

      newImagesLoading.forEach((image) => {
        checkImage(image).then(({ image, img }) => {
          console.log(image)
          console.log(img)
          if (resolveImage) {
            resolveImage(image, img)
          }
          dispatch(setImageLoaded({ id: image.id }))
        })
      })
    }
  }, [imagesNoLoaded, imagesLoading, dispatch, resolveImage])

  return <IconLoadingNumber />
}

export default ImageLoader
