import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCoors, setOutside } from '../../modules/reducer'
import { setTexture } from '../../modules/reducerTextures'
import { setBounds } from '../../modules/reducerDimensions'
import { getLoaded } from '../../modules/reducerLoadImages'
import { getIdHotspots } from '../../modules/reducerHotspots'

import Measure from 'react-measure'

import Scene from '../Scene/Scene'
import ImageLoader from './ImageLoader'
import Hotspot from './Hotspot'

const ThreeContainer = () => {
  const loaded = useSelector(getLoaded)

  const idHotspots = useSelector(getIdHotspots)

  const dispatch = useDispatch()

  const onResize = useCallback(
    contentRect =>
      dispatch(
        setBounds({
          x: contentRect.bounds.left,
          y: contentRect.bounds.top,
          width: contentRect.bounds.width,
          height: contentRect.bounds.height
        })
      ),
    [dispatch]
  )

  const resolveImage = useCallback(
    (image, img) => dispatch(setTexture(image.id, img)),
    [dispatch]
  )

  const onMouseDownHandler = useCallback(
    e => {
      e.preventDefault()
      dispatch(setCoors('down', { x: e.clientX, y: e.clientY }))
    },
    [dispatch]
  )

  const onMouseMoveHandler = useCallback(
    e => {
      e.preventDefault()
      dispatch(setCoors('move', { x: e.clientX, y: e.clientY }))
    },
    [dispatch]
  )

  const onMouseUpHandler = useCallback(
    e => {
      e.preventDefault()
      dispatch(setCoors('up', { x: e.clientX, y: e.clientY }))
    },
    [dispatch]
  )

  const onMouseLeaveHandler = useCallback(
    e => {
      e.preventDefault()
      dispatch(setOutside(true))
    },
    [dispatch]
  )

  const onMouseEnterHandler = useCallback(
    e => {
      e.preventDefault()
      dispatch(setOutside(false))
    },
    [dispatch]
  )

  return (
    <Measure bounds onResize={onResize}>
      {({ measureRef }) => (
        <div
          ref={measureRef}
          style={{ width: '100%', height: '100vh', overflow: 'hidden' }}
          onMouseDown={onMouseDownHandler}
          onMouseUp={onMouseUpHandler}
          onMouseMove={onMouseMoveHandler}
          onMouseLeave={onMouseLeaveHandler}
          onMouseEnter={onMouseEnterHandler}
        >
          {loaded ? (
            <>
              <Scene />
              {idHotspots.map(idHotspot => (
                <Hotspot key={idHotspot} id={idHotspot} />
              ))}
            </>
          ) : (
            <ImageLoader resolveImage={resolveImage} />
          )}
        </div>
      )}
    </Measure>
  )
}

export default ThreeContainer
