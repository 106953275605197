import React, { useRef } from 'react'
import { BackSide } from 'three'
import { a } from 'react-spring/three'
import { useSpring } from 'react-spring/three'

import ShaderCube from './ShaderCube'

const SkyBox = ({
  sizeSkyBox,
  actualPosition,
  antTexture,
  actualTexture,
  numChangesCapas,
  progressCubes,
  numChangesCubes,
  clase
}) => {
  const { progressCapas } = useSpring({
    progressCapas: numChangesCapas % 2 === 0 ? 1 : 0
  })

  let progress = null
  let actualTexture1 = actualTexture
  let antTexture1 = antTexture

  if (clase === 'cubes') {
    progress = progressCubes

    if (numChangesCubes % 2 !== 0) {
      actualTexture1 = antTexture
      antTexture1 = actualTexture
    }
  } else {
    progress = progressCapas

    if (numChangesCapas % 2 !== 0) {
      actualTexture1 = antTexture
      antTexture1 = actualTexture
    }
  }

  /*
  let actualTexture1 = actualTexture
  let antTexture1 = antTexture

  if (numChangesCapas % 2 !== 0) {
    actualTexture1 = antTexture
    antTexture1 = actualTexture
  }*/

  const cube = useRef()

  if (cube.current) {
    cube.current.onBeforeRender = function(gl, scene, camera) {
      this.matrixWorld.copyPosition(camera.matrixWorld)
    }
  }

  return actualTexture === null ? null : (
    <mesh ref={cube} position={actualPosition}>
      <boxBufferGeometry
        attach="geometry"
        args={[sizeSkyBox, sizeSkyBox, sizeSkyBox]}
      />
      <a.shaderMaterial
        attach="material"
        args={[
          {
            uniforms: ShaderCube.uniforms,
            vertexShader: ShaderCube.vertexShader,
            fragmentShader: ShaderCube.fragmentShader,
            side: BackSide,
            depthTest: true,
            depthWrite: false,
            fog: false
            //transparent: true
          }
        ]}
        uniforms-progress-value={progress}
        uniforms-initialTexture-value={antTexture1}
        uniforms-lastTexture-value={actualTexture1}
      />
    </mesh>
  )
}

export default React.memo(SkyBox)
