import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setCaptureImage,
  setFull,
  //setMeasuring,
  toggleMeasuring
} from '../../modules/reducer'
//import { setTextureIndex } from './modules/reducerObject'

import { makeStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import CloseIcon from '@material-ui/icons/Close'
import WallpaperIcon from '@material-ui/icons/Wallpaper'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'

const useStyles = makeStyles({
  flex: {
    flexGrow: 1
  },
  buttonsRight: {
    marginLeft: 'auto'
  },
  iconButton: {
    fontSize: '48px'
  },
  slider: {
    width: '50%'
  },
  appBar: {
    /*background: 'transparent'*/ /* opacity: 0.6,*/
    background: 'rgba(0, 0, 0, 0.6)'
    /*boxShadow: 'none'*/
  }
})

const AppBarCubes = () => {
  const dispatch = useDispatch()
  const isFull = useSelector(state => state.reducer.isFull)
  const measuring = useSelector(state => state.reducer.measuring)

  const setFullTrue = useCallback(() => dispatch(setFull(true)), [dispatch])
  const setFullFalse = useCallback(() => dispatch(setFull(false)), [dispatch])
  const setCaptureImageD = useCallback(() => dispatch(setCaptureImage(true)), [
    dispatch
  ])
  /*const setTextureIndexD = useCallback(() => dispatch(setTextureIndex(1)), [
    dispatch
  ])*/
  /*const setMeasuringFalse = useCallback(() => dispatch(setMeasuring(false)), [
    dispatch
  ])
  const setMeasuringTrue = useCallback(() => dispatch(setMeasuring(true)), [
    dispatch
  ])*/
  const toggleMeasuringD = useCallback(() => dispatch(toggleMeasuring()), [
    dispatch
  ])

  const classes = useStyles()

  const buttonMeasure = measuring ? (
    <IconButton aria-label="Measure" color="inherit" onClick={toggleMeasuringD}>
      <CloseIcon />
    </IconButton>
  ) : (
    <IconButton aria-label="Measure" color="inherit" onClick={toggleMeasuringD}>
      <SettingsEthernetIcon />
    </IconButton>
  )

  const buttonFull = !isFull ? (
    <IconButton aria-label="Fullscreen" color="inherit" onClick={setFullTrue}>
      <FullscreenIcon />
    </IconButton>
  ) : (
    <IconButton aria-label="Fullscreen" color="inherit" onClick={setFullFalse}>
      <FullscreenExitIcon />
    </IconButton>
  )

  return (
    <AppBar position="absolute" color="secondary" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" color="inherit">
          Virtual Tour
        </Typography>
        <div className={classes.buttonsRight}>
          <IconButton
            aria-label="Capture"
            color="inherit"
            onClick={setCaptureImageD}
          >
            <WallpaperIcon />
          </IconButton>

          {buttonMeasure}

          {buttonFull}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default AppBarCubes
