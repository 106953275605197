//Actions
export const SET_BOUNDS = 'SET_BOUNDS'
export const SET_POSITION_Y = 'SET_POSITION_Y'
export const SET_SLIDER_Y = 'SET_SLIDER_Y'
export const SET_MOVING_Y = 'SET_MOVING_Y'
export const SET_HEIGHT_MENU = 'SET_HEIGHT_MENU'
export const SET_HEIGHT_VIEWPORT = 'SET_HEIGHT_VIEWPORT'

const initialState = {
  bounds: {
    x: 0,
    y: 0,
    width: 0,
    heigth: 0
  },
  positionY: 0,
  movingY: false,
  sliderY: 0,
  heightMenu: 0,
  heightViewport: 0
}

export const setBounds = (bounds) => ({
  type: SET_BOUNDS,
  bounds
})

export const setPositionY = (positionY) => ({
  type: SET_POSITION_Y,
  positionY
})

export const setMovingY = (movingY) => ({
  type: SET_MOVING_Y,
  movingY
})

export const setSliderY = (sliderY) => ({
  type: SET_SLIDER_Y,
  sliderY
})

export const setHeightMenu = (height) => ({
  type: SET_HEIGHT_MENU,
  height
})

export const setHeightViewport = (height) => ({
  type: SET_HEIGHT_VIEWPORT,
  height
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }
    case SET_POSITION_Y:
      return {
        ...state,
        positionY: action.positionY
      }
    case SET_MOVING_Y:
      return {
        ...state,
        movingY: action.movingY
      }
    case SET_SLIDER_Y:
      return {
        ...state,
        sliderY: action.sliderY
      }
    case SET_HEIGHT_MENU:
      return {
        ...state,
        heightMenu: action.height
      }
    case SET_HEIGHT_VIEWPORT:
      return {
        ...state,
        heightViewport: action.height
      }
    default:
      return state
  }
}

export const getEndMenu = (state) => {
  return -(state.dimensions.heightMenu - state.dimensions.heightViewport)
}
