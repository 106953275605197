import React, { useMemo, useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getOpcion, setOpcionSeleccionada } from '../../modules/reducerTextures'

import IconMark from './IconMark'

import IconEuro from './IconEuro'

const useStyles = createUseStyles({
  wrapperFrame: {
    cursor: 'pointer',
    backgroundColor: ({ visible, theme }) =>
      visible ? theme.backgroundFrame : 'transparent',
    display: 'flex'
  },
  wrapperIcono: { position: 'relative' },
  icono: {
    borderRadius: '50%',
    height: 70,
    width: 70
  },
  title: { fontWeight: 500, whiteSpace: 'normal', fontSize: '1.2rem' },
  subtitle: { fontWeight: 300, whiteSpace: 'normal' },
  precio: { paddingLeft: '1.5em', fontSize: '1.2rem' },
  wrapperTexto: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  wrapperPrecio: { textAlign: 'right' }
})

const Frame = ({ id, idAcabado, visible, padding }) => {
  const dispatch = useDispatch()

  const getO = useMemo(getOpcion, [])
  const { srcIcono, title, subtitle, precio } = useSelector((state) =>
    getO(state, id)
  )

  const onClick = useCallback(() => {
    dispatch(setOpcionSeleccionada(idAcabado, id))
  }, [dispatch, id, idAcabado])

  const theme = useTheme()
  const classes = useStyles({
    visible,
    theme
  })

  return (
    <div
      className={classes.wrapperFrame}
      style={{ padding: '5px 0 5px 0' }}
      onClick={onClick}
    >
      <div className={classes.wrapperIcono} style={{ marginLeft: padding }}>
        <img className={classes.icono} alt="" src={srcIcono} />
        {visible ? <IconMark size={26} /> : null}
      </div>
      <div className={classes.wrapperTexto}>
        <div style={{ paddingLeft: 20 }}>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
        <div
          className={classes.wrapperPrecio}
          style={{ paddingRight: padding / 2 }}
        >
          <IconEuro />
          <span className={classes.precio}>{precio}</span>
        </div>
      </div>
    </div>
  )
}

export default Frame
