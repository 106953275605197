import { combineReducers } from 'redux'
import reducer from './reducer'
import reducerObject from './reducerObject'
import reducerDimensions from './reducerDimensions'
import reducerLoadImages from './reducerLoadImages'
import reducerTextures from './reducerTextures'
import reducerChanges from './reducerChanges'
import reducerHotspots from './reducerHotspots'
import reducerUI from './reducerUI'
import reducerNocturno from './reducerNocturno'

export default combineReducers({
  reducer,
  reducerObject,
  dimensions: reducerDimensions,
  loadImages: reducerLoadImages,
  textures: reducerTextures,
  changes: reducerChanges,
  hotspots: reducerHotspots,
  ui: reducerUI,
  nocturno: reducerNocturno
})
