import { useEffect } from 'react'

import store from '../../../store'
import { changeCubeModelOpcion } from '../../../modules/reducerChanges'

import { changeCubeOpcion$ } from '../../../modules/observables'

const useChangeCube = () => {
  useEffect(() => {
    const subscription = changeCubeOpcion$.subscribe(newValue => {
      store.dispatch(changeCubeModelOpcion(newValue))
    })

    return () => subscription.unsubscribe()
  }, [])
}

export default useChangeCube
