import React, { useMemo, useCallback } from 'react'

import { useSelector } from 'react-redux'

import { getHotspot } from '../../modules/reducerHotspots'

import HotspotPoint from './HotspotPoint'
import HotspotArea from './HotspotArea'

const Hotspot = ({ id }) => {
  const getH = useMemo(getHotspot, [])

  const { tooltip, type, points } = useSelector(state => getH(state, id))

  const onClick = useCallback(e => {}, [])

  return type === 'point' ? (
    <HotspotPoint points={points} tooltip={tooltip} onClick={onClick} />
  ) : (
    <HotspotArea points={points} tooltip={tooltip} onClick={onClick} />
  )
}

export default Hotspot
