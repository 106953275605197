import React from 'react'

import { useSelector } from 'react-redux'
import { getAcabados } from '../../modules/reducerChanges'
import { nocturnoSelector } from '../../modules/reducerNocturno'

import { useSpring, animated } from 'react-spring'

import Paper from '@material-ui/core/Paper'
import { createUseStyles } from 'react-jss'

import Acabado from './Acabado'
import MenuTotales from './MenuTotales'
import MenuViewport from './MenuViewport'

const AnimatedPaper = animated(Paper)

const useStyles = createUseStyles({
  menu: {
    position: 'absolute',
    top: 80,
    //left: 20,
    width: 330,
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const MenuEstancias = () => {
  const acabados = useSelector(getAcabados)
  const nocturno = useSelector(nocturnoSelector)

  const { left } = useSpring({
    left: nocturno ? -330 : 20
  })

  const padding = 20

  const classes = useStyles()

  return (
    <AnimatedPaper elevation={3} className={classes.menu} style={{ left }}>
      <MenuViewport>
        {acabados.map((idAcabado) => (
          <Acabado key={idAcabado} id={idAcabado} padding={padding} />
        ))}
      </MenuViewport>
      <MenuTotales padding={padding} />
    </AnimatedPaper>
  )
}

export default MenuEstancias
