const morado = '#932032'
const grisOscuro = '#434343'

export const theme = {
  textColor: grisOscuro,
  colorPrincipal: morado,
  backgroundImages: '#e0e0e0',
  backgroundFrame: '#eeeeee',

  backgroundHeader: 'white',
  backgroundFooter: grisOscuro,
  iconnHeaderColor: morado,
  menuIdiomasColor: morado,
  shadowHeader: true,

  mediaScroll: '823px',
  mediaMovil: '414px',

  lg: '80em',
  md: '64em',
  sm: '52em',
  xs: '36em'
}

export default theme
