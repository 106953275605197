import settings from './settings'
import { Mesh, BoxBufferGeometry } from 'three'
import { createSelector } from 'reselect'

import { idActualCubeSelector, idAntCubeSelector } from './reducerChanges'

import { searchId } from './utils'

const cubes = settings.positions.map(element => {
  const cube = new Mesh(new BoxBufferGeometry(1500, 1500, 1500))
  cube.position.fromArray(element.position)
  return { id: element.id, cube }
})

//States
export const SET_CAPTURE_IMAGE = 'SET_CAPTURE_IMAGE'

//Actions
export const SET_LOADED = 'SET_LOADED'
export const SET_COORS = 'SET_COORS'
export const SET_OUTSIDE = 'SET_OUTSIDE'

export const TOGGLE_MEASURING = 'TOGGLE_MEASURING'

export const SET_MOVE_MODEL = 'SET_MOVE_MODEL'
export const SET_DOWN_MODEL = 'SET_DOWN_MODEL'
export const SET_UP_MODEL = 'SET_UP_MODEL'

export const SET_FULL = 'SET_FULL'

const initialState = {
  model: settings.model,
  cubes: cubes,
  isFull: false,
  loaded: false,
  measuring: false,
  outside: false,
  captureImage: false,
  coors: {
    down: { x: 0, y: 0 },
    move: { x: 0, y: 0 },
    up: { x: 0, y: 0 }
  },

  coorsModel: {
    down: { x: 0, y: 0, z: 0 },
    move: { x: 0, y: 0, z: 0 },
    up: { x: 0, y: 0, z: 0 }
  },

  dataMoveModel: {
    normal: { x: 0, y: 0, z: 0 },
    normalMatrix: null
  }
}

export const setFull = isFull => ({
  type: SET_FULL,
  isFull
})

export const setLoaded = loaded => ({
  type: SET_LOADED,
  loaded
})

export const toggleMeasuring = () => ({
  type: TOGGLE_MEASURING
})

export const setOutside = outside => ({
  type: SET_OUTSIDE,
  outside
})

export const setCoors = (event, coors) => ({
  type: SET_COORS,
  event,
  coors
})

export const setMoveModel = ({ point, normal, normalMatrix }) => ({
  type: SET_MOVE_MODEL,
  point,
  normal,
  normalMatrix
})

export const setDownModel = point => ({
  type: SET_DOWN_MODEL,
  point
})

export const setUpModel = point => ({
  type: SET_UP_MODEL,
  point
})

export const setCaptureImage = value => ({
  type: SET_CAPTURE_IMAGE,
  value
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADED:
      console.log('loaded')
      return {
        ...state,
        loaded: action.loaded
      }
    case SET_FULL:
      return {
        ...state,
        isFull: action.isFull
      }
    case TOGGLE_MEASURING:
      return {
        ...state,
        measuring: !state.measuring
      }
    case SET_OUTSIDE:
      console.log('outside')
      return {
        ...state,
        outside: action.outside
      }
    case SET_CAPTURE_IMAGE:
      return {
        ...state,
        captureImage: action.value
      }
    case SET_COORS:
      const coors = { ...state.coors }
      coors[action.event] = action.coors

      return {
        ...state,
        coors
      }
    case SET_MOVE_MODEL:
      return {
        ...state,
        coorsModel: {
          ...state.coorsModel,
          move: action.point
        },
        dataMoveModel: {
          normal: action.normal,
          normalMatrix: action.normalMatrix
        }
      }
    case SET_DOWN_MODEL:
      return {
        ...state,
        coorsModel: {
          ...state.coorsModel,
          down: action.point
        }
      }
    case SET_UP_MODEL:
      return {
        ...state,
        coorsModel: {
          ...state.coorsModel,
          up: action.point
        }
      }
    default:
      return state
  }
}

const cubesSelector = state => state.reducer.cubes
export const rulesSelector = state => state.reducer.rules

export const getCoors = (state, event) => {
  return {
    x: state.reducer.coors[event].x - state.dimensions.bounds.x,
    y: state.reducer.coors[event].y - state.dimensions.bounds.y
  }
}

export const getActualCube = createSelector(
  [cubesSelector, idActualCubeSelector],
  (cubes, idActualCube) => ({
    id: idActualCube,
    cube: searchId(cubes, idActualCube).cube
  })
)

export const getAntCube = createSelector(
  [cubesSelector, idAntCubeSelector],
  (cubes, idAntCube) => ({
    id: idAntCube,
    cube: searchId(cubes, idAntCube).cube
  })
)
