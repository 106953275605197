import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { withStyles } from '@material-ui/core/styles'

import { useSelector, useDispatch } from 'react-redux'
import { nocturnoSelector, toggleNocturno } from '../../modules/reducerNocturno'

import { IconContext } from 'react-icons'
import { RiSunLine, RiMoonLine } from 'react-icons/ri'

import Switch from '@material-ui/core/Switch'

const useStyles = createUseStyles({
  nocturno: {
    position: 'absolute',
    top: 80,
    right: 20,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {}
})

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#932032',
    '&$checked': {
      color: '#932032'
    },
    '&$checked + $track': {
      backgroundColor: '#932032'
    }
  },
  checked: {},
  track: {}
})(Switch)

const Nocturno = () => {
  const nocturno = useSelector(nocturnoSelector)

  const dispatch = useDispatch()
  const onChange = useCallback(() => {
    dispatch(toggleNocturno())
  }, [dispatch])

  const classes = useStyles()

  return (
    <div className={classes.nocturno}>
      <IconContext.Provider value={{ size: '1.5em' }}>
        <RiSunLine />
      </IconContext.Provider>
      <PurpleSwitch
        checked={nocturno}
        onChange={onChange}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <IconContext.Provider value={{ size: '1.5em' }}>
        <RiMoonLine />
      </IconContext.Provider>
    </div>
  )
}

export default Nocturno
