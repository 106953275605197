import { useRef, useEffect } from 'react'

import store from '../../../store'
import { toggleMeasuring } from '../../../modules/reducer'

import * as THREE from 'three'
import { useThree } from 'react-three-fiber'

import useObservable from '../useObservable'
import {
  clickModelMeasuring$,
  moveModelMeasuring$
} from '../../../modules/observables'

const materialSphere = new THREE.MeshNormalMaterial({
  transparent: true,
  opacity: 1
})

const materialLine = new THREE.LineBasicMaterial({
  color: 'red',
  transparent: true,
  opacity: 1
})

const geometrySphere = new THREE.SphereBufferGeometry(1, 64, 64)

const geometryPlane = new THREE.PlaneBufferGeometry(30, 8)

const dir = new THREE.Vector3()
const raycaster = new THREE.Raycaster()
const getIntersect = (orig, dest, model) => {
  dir.subVectors(dest, orig).normalize()
  raycaster.set(orig, dir)
  const inters = raycaster.intersectObject(model, true)

  if (inters.length > 0) {
    const distanceDest = orig.distanceTo(dest).toFixed(5) * 1
    const distanceInter = inters[0].distance.toFixed(5) * 1

    return distanceDest > distanceInter
  } else {
    return false
  }
}

const Rules = ({ model, actualPosition }) => {
  const { scene } = useThree()

  const intersectMove = useObservable(moveModelMeasuring$, null)
  const intersectClick = useObservable(clickModelMeasuring$, null)

  const helperInit = useRef(null)
  const helperEnd = useRef(null)

  const geometryLine = useRef(null)
  const line = useRef(null)

  const rules = useRef([])

  useEffect(() => {
    rules.current.forEach(({ start, end, text }) => {
      if (!getIntersect(actualPosition, start.position, model)) {
        if (!getIntersect(actualPosition, end.position, model)) {
          text.material.setValues({ depthTest: false })
        } else {
          text.material.setValues({ depthTest: true })
        }
      } else {
        text.material.setValues({ depthTest: true })
      }
    })
  }, [model, actualPosition])

  useEffect(() => {
    if (intersectClick !== null) {
      if (intersectClick.start) {
        helperInit.current = new THREE.Mesh(geometrySphere, materialSphere)
        helperEnd.current = new THREE.Mesh(geometrySphere, materialSphere)

        helperInit.current.position.copy(intersectClick.point)
        helperEnd.current.position.copy(intersectClick.point)
        helperInit.current.renderOrder = 5
        helperEnd.current.renderOrder = 5
        scene.add(helperInit.current)
        scene.add(helperEnd.current)

        geometryLine.current = new THREE.BufferGeometry()
        line.current = new THREE.Line(geometryLine.current, materialLine)

        line.current.renderOrder = 5
        scene.add(line.current)
      } else {
        const text =
          Math.round(
            helperInit.current.position.distanceTo(helperEnd.current.position) *
              10
          ) + ' mm'

        var canvas = document.createElement('canvas')
        var context = canvas.getContext('2d')
        const textHeight = 50
        context.font = 'normal ' + textHeight + 'px Arial'
        const metrics = context.measureText(text)
        const textWidth = metrics.width
        canvas.width = textWidth
        canvas.height = textHeight
        context.font = 'normal ' + textHeight + 'px Arial'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        context.fillStyle = '#000000'
        context.fillText(text, textWidth / 2, textHeight / 2)

        var texture = new THREE.Texture(canvas)
        texture.needsUpdate = true

        const materialPlane = new THREE.MeshBasicMaterial({
          transparent: true,
          map: texture,
          color: 16777215,
          depthTest: false,
          sizeAttenuation: false
        })

        const midPoint = new THREE.Vector3(
          (helperInit.current.position.x + helperEnd.current.position.x) / 2,
          (helperInit.current.position.y + helperEnd.current.position.y) / 2,
          (helperInit.current.position.z + helperEnd.current.position.z) / 2
        )

        const plane = new THREE.Mesh(geometryPlane, materialPlane)
        plane.overdraw = true
        plane.renderOrder = 5
        plane.position.copy(midPoint)
        plane.onBeforeRender = function(gl, scene, camera) {
          this.quaternion.copy(camera.quaternion)

          /*const scale =
            new THREE.Vector3().subVectors(midPoint, camera.position).length() /
            150
          plane.scale.set(scale, scale, 1)*/
        }

        const scale = 0.6
        plane.scale.set(scale, scale, 1)

        scene.add(plane)

        rules.current.push({
          start: helperInit.current,
          end: helperEnd.current,
          text: plane
        })

        store.dispatch(toggleMeasuring())
      }
    }
  }, [scene, intersectClick])

  useEffect(() => {
    if (
      intersectClick !== null &&
      intersectMove !== null &&
      intersectClick.start
    ) {
      geometryLine.current.setFromPoints([
        intersectClick.point,
        intersectMove.point
      ])
      geometryLine.current.computeBoundingSphere()
      helperEnd.current.position.copy(intersectMove.point)
    }
  }, [intersectClick, intersectMove])

  return null
}

export default Rules
