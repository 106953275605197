export const ShaderCube = {
  uniforms: {
    progress: {
      type: 'f',
      value: 0
    },
    initialTexture: {
      type: 't',
      value: null
    },
    lastTexture: {
      type: 't',
      value: null
    }
  },
  vertexShader: [
    'varying vec3 position1;',
    'void main() {',
    'position1 = position;',
    'position1.x *= -1.0;',
    'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
    '}'
  ].join('\n'),
  fragmentShader: [
    'uniform float progress;',
    'uniform samplerCube initialTexture;',
    'uniform samplerCube lastTexture;',
    'varying vec3 position1;',
    'void main() {',
    'vec4 initialColor = textureCube(initialTexture, position1);',
    'vec4 lastColor = textureCube(lastTexture, position1);',
    'vec4 color = mix(initialColor, lastColor, progress);',
    'gl_FragColor = vec4(color.rgb, 1.0);',
    '}'
  ].join('\n')
}

export default ShaderCube
