const settings = {
  'initial cube': 'p2',
  'show model': false,
  'skybox size': 0,
  'transition duration': 1,
  'rotation velocity': 1.5,
  fov: 60,
  model: 'GEOMETRIA.FBX',
  positions: [
    {
      id: 'p1',
      acabados: ['a1'],
      path: '01',
      position: [285.833, 150, -1675.59],
      vecinos: [
        'p35',
        'p2',
        'p36',
        'p3',
        'p6',
        'p7',
        'p13',
        'p8',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p2',
      acabados: ['a1'],
      path: '02',
      position: [285.833, 150, -1429.52],
      vecinos: [
        'p35',
        'p1',
        'p36',
        'p3',
        'p6',
        'p7',
        'p13',
        'p8',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15',
        'p5',
        'p25',
        'p26',
        'p27',
        'p28',
        'p29',
        'p30'
      ]
    },
    {
      id: 'p3',
      acabados: ['a1'],
      path: '03',
      position: [285.833, 150, -1235.18],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p6',
        'p7',
        'p13',
        'p4',
        'p5',
        'p8',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p4',
      acabados: ['a1'],
      path: '04',
      position: [452.008, 150, -1235.18],
      vecinos: [
        'p36',
        'p3',
        'p5',
        'p6',
        'p7',
        'p8',
        'p9',
        'p10',
        'p11',
        'p12',
        'p13',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p5',
      acabados: ['a1'],
      path: '05',
      position: [452.008, 150, -1113.24],
      vecinos: [
        'p2',
        'p36',
        'p3',
        'p4',
        'p6',
        'p7',
        'p8',
        'p9',
        'p10',
        'p11',
        'p12',
        'p13',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p6',
      acabados: ['a1'],
      path: '06',
      position: [285.113, 150, -1113.24],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p3',
        'p7',
        'p13',
        'p4',
        'p5',
        'p8',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p7',
      acabados: ['a1'],
      path: '07',
      position: [285.113, 150, -933.893],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p3',
        'p6',
        'p13',
        'p4',
        'p5',
        'p8',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p8',
      acabados: ['a1'],
      path: '08',
      position: [413.326, 150, -933.893],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p3',
        'p6',
        'p13',
        'p4',
        'p5',
        'p7',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p9',
      acabados: ['a1'],
      path: '09',
      position: [413.326, 150, -782.806],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p3',
        'p6',
        'p13',
        'p4',
        'p5',
        'p8',
        'p7',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p10',
      acabados: ['a1'],
      path: '10',
      position: [413.326, 150, -601.604],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p3',
        'p6',
        'p13',
        'p4',
        'p5',
        'p8',
        'p7',
        'p9',
        'p12',
        'p11',
        'p14',
        'p15',
        'p16'
      ]
    },
    {
      id: 'p11',
      acabados: ['a1'],
      path: '11',
      position: [612.271, 150, -601.604],
      vecinos: [
        'p2',
        'p36',
        'p3',
        'p6',
        'p13',
        'p4',
        'p5',
        'p8',
        'p7',
        'p9',
        'p12',
        'p10',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p12',
      acabados: ['a1'],
      path: '12',
      position: [413.326, 150, -459.176],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p3',
        'p4',
        'p5',
        'p6',
        'p7',
        'p8',
        'p9',
        'p10',
        'p11',
        'p13',
        'p14',
        'p15',
        'p16',
        'p17'
      ]
    },
    {
      id: 'p13',
      acabados: ['a1'],
      path: '13',
      position: [272.991, 150, -459.176],
      vecinos: [
        'p1',
        'p35',
        'p2',
        'p36',
        'p3',
        'p4',
        'p5',
        'p6',
        'p7',
        'p8',
        'p9',
        'p10',
        'p11',
        'p12',
        'p14',
        'p15',
        'p16',
        'p17'
      ]
    },
    {
      id: 'p14',
      acabados: ['a1'],
      path: '14',
      position: [564.186, 150, -459.176],
      vecinos: [
        'p36',
        'p3',
        'p4',
        'p5',
        'p6',
        'p7',
        'p8',
        'p9',
        'p10',
        'p11',
        'p12',
        'p13',
        'p15',
        'p16',
        'p17'
      ]
    },
    {
      id: 'p15',
      acabados: ['a1'],
      path: '15',
      position: [564.186, 150, -272.953],
      vecinos: [
        'p36',
        'p3',
        'p4',
        'p5',
        'p6',
        'p7',
        'p8',
        'p9',
        'p10',
        'p11',
        'p12',
        'p13',
        'p14',
        'p16',
        'p17'
      ]
    },
    {
      id: 'p16',
      acabados: ['a1'],
      path: '16',
      position: [768.124, 150, -459.176],
      vecinos: ['p10', 'p12', 'p13', 'p14', 'p15', 'p17', 'p19']
    },
    {
      id: 'p17',
      acabados: ['a1'],
      path: '17',
      position: [935.893, 150, -459.176],
      vecinos: ['p12', 'p13', 'p14', 'p15', 'p17', 'p19', 'p18']
    },
    {
      id: 'p18',
      acabados: ['a1'],
      path: '18',
      position: [935.893, 150, -862.817],
      vecinos: ['p15', 'p16', 'p17', 'p19']
    },
    {
      id: 'p19',
      acabados: ['a1'],
      path: '19',
      position: [1094.68, 150, -862.817],
      vecinos: ['p15', 'p16', 'p17', 'p18', 'p20']
    },
    {
      id: 'p20',
      acabados: ['a1'],
      path: '20',
      position: [1094.68, 150, -1044.88],
      vecinos: ['p19', 'p21', 'p22', 'p23', 'p24', 'p25']
    },
    {
      id: 'p21',
      acabados: ['a1'],
      path: '21',
      position: [957.811, 150, -1044.88],
      vecinos: ['p20', 'p22', 'p23', 'p24', 'p25']
    },
    {
      id: 'p22',
      acabados: ['a1'],
      path: '22',
      position: [957.811, 150, -1204.26],
      vecinos: ['p20', 'p21', 'p23', 'p24', 'p25']
    },
    {
      id: 'p23',
      acabados: ['a1'],
      path: '23',
      position: [957.811, 150, -1322.4],
      vecinos: ['p20', 'p21', 'p22', 'p24', 'p25']
    },
    {
      id: 'p24',
      acabados: ['a1'],
      path: '24',
      position: [1090.1, 150, -1322.4],
      vecinos: ['p19', 'p20', 'p21', 'p22', 'p23', 'p26']
    },
    {
      id: 'p25',
      acabados: ['a1'],
      path: '25',
      position: [957.811, 150, -1427.9],
      vecinos: [
        'p20',
        'p21',
        'p22',
        'p23',
        'p26',
        'p27',
        'p28',
        'p2',
        'p29',
        'p30'
      ]
    },
    {
      id: 'p26',
      acabados: ['a1'],
      path: '26',
      position: [798.015, 150, -1427.9],
      vecinos: ['p25', 'p27', 'p28', 'p29', 'p30', 'p2', 'p24']
    },
    {
      id: 'p27',
      acabados: ['a1'],
      path: '27',
      position: [623.743, 150, -1427.9],
      vecinos: ['p25', 'p26', 'p28', 'p29', 'p30', 'p2']
    },
    {
      id: 'p28',
      acabados: ['a1'],
      path: '28',
      position: [449.47, 150, -1427.9],
      vecinos: ['p25', 'p26', 'p27', 'p29', 'p30', 'p2']
    },
    {
      id: 'p29',
      acabados: ['a1'],
      path: '29',
      position: [1122.43, 150, -1427.9],
      vecinos: ['p25', 'p26', 'p27', 'p28', 'p30', 'p2', 'p31']
    },
    {
      id: 'p30',
      acabados: ['a1'],
      path: '30',
      position: [1287.05, 150, -1427.9],
      vecinos: ['p25', 'p26', 'p27', 'p28', 'p29', 'p2', 'p31', 'p32']
    },
    {
      id: 'p31',
      acabados: ['a1'],
      path: '31',
      position: [1122.43, 150, -1609.2],
      vecinos: ['p29', 'p30', 'p32', 'p33', 'p34']
    },
    {
      id: 'p32',
      acabados: ['a1'],
      path: '32',
      position: [987.756, 150, -1609.2],
      vecinos: ['p30', 'p31', 'p33', 'p34']
    },
    {
      id: 'p33',
      acabados: ['a1'],
      path: '33',
      position: [853.083, 150, -1609.2],
      vecinos: ['p31', 'p32', 'p34']
    },
    {
      id: 'p34',
      acabados: ['a1'],
      path: '34',
      position: [764.578, 150, -1609.2],
      vecinos: ['p31', 'p32', 'p33']
    },
    {
      id: 'p35',
      acabados: ['a1'],
      path: '35',
      position: [285.833, 150, -1562.74],
      vecinos: [
        'p1',
        'p2',
        'p36',
        'p3',
        'p6',
        'p7',
        'p13',
        'p8',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15'
      ]
    },
    {
      id: 'p36',
      acabados: ['a1'],
      path: '36',
      position: [285.833, 150, -1322.25],
      vecinos: [
        'p35',
        'p2',
        'p1',
        'p3',
        'p6',
        'p7',
        'p13',
        'p8',
        'p9',
        'p10',
        'p12',
        'p11',
        'p14',
        'p15',
        'p4',
        'p5'
      ]
    }
  ],
  acabados: [
    {
      id: 'a1',
      /*title: [
        { id: 'es', texto: 'Solería' },
        { id: 'en', texto: 'Solería Ingles' }
      ],*/
      title: 'Solería',
      opciones: ['op1', 'op2', 'op3', 'op4']
    }
  ],
  opciones: [
    {
      id: 'op1',
      title: 'Base',
      precio: 0,
      srcIcono: 'images/iconos/base.jpg',
      patron: 'images/base/big_base_000'
    },
    {
      id: 'op2',
      title: 'Grafito',
      precio: 1000,
      srcIcono: 'images/iconos/grafito.jpg',
      patron: 'images/grafito/big_grafito_000'
    },
    {
      id: 'op3',
      title: 'Pino',
      precio: 2000,
      srcIcono: 'images/iconos/pino.jpg',
      patron: 'images/pino/big_base_pno_000'
    },
    {
      id: 'op4',
      title: 'Roble',
      precio: 3000,
      srcIcono: 'images/iconos/roble.jpg',
      patron: 'images/roble/big_base_roble_000'
    }
  ],
  opcionesSeleccionadas: [{ id: 'a1', opciones: ['op1'] }],
  precioBase: 0
}

export default settings
