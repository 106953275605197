import React, { useRef, useCallback } from 'react'
import * as THREE from 'three'
import { useThree } from 'react-three-fiber'
import { a } from 'react-spring/three'

import CameraRotate from './CameraRotate'

const lerp = (delta, last, prev) => {
  return [
    THREE.Math.lerp(prev.x, last.x, delta),
    THREE.Math.lerp(prev.y, last.y, delta),
    THREE.Math.lerp(prev.z, last.z, delta)
  ]
}

const CameraMove = ({ progress, numChanges, actualCube, antCube }) => {
  const { setDefaultCamera } = useThree()

  const camera = useRef(null)

  const cameraCallback = useCallback(
    node => {
      if (node !== null) {
        setDefaultCamera(node)
        camera.current = node
      }
    },
    [setDefaultCamera]
  )

  const { width, height } = useThree().size

  let actualCube1 = actualCube
  let antCube1 = antCube

  if (numChanges % 2 !== 0) {
    actualCube1 = antCube
    antCube1 = actualCube
  }

  return (
    <>
      <a.perspectiveCamera
        ref={cameraCallback}
        aspect={width / height}
        fov={70}
        near={0.1}
        far={1500}
        onUpdate={self => self.updateProjectionMatrix()}
        position={progress.interpolate(i => lerp(i, actualCube1, antCube1))}
      />
      {camera.current ? <CameraRotate camera={camera.current} /> : null}
    </>
  )
}

export default CameraMove
