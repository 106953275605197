import { useRef } from 'react'

import * as THREE from 'three'

import { rotate$ } from '../../../modules/observables'
import useObservable from '../useObservable'

const CameraRotate = ({ camera }) => {
  const target = useRef(new THREE.Vector3())

  const coors = useObservable(rotate$, { lat: 0, lon: 0 })

  target.current.copy(camera.position)

  const phi = THREE.Math.degToRad(90 - coors.lat)
  const theta = THREE.Math.degToRad(coors.lon)

  target.current.x = 150 * Math.sin(phi) * Math.cos(theta) + camera.position.x
  target.current.y = 150 * Math.cos(phi) + camera.position.y
  target.current.z = 150 * Math.sin(phi) * Math.sin(theta) + camera.position.z

  camera.lookAt(target.current)

  return null
}

export default CameraRotate
