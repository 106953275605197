import { useState, useEffect } from 'react'

import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

const useModel = url => {
  const [model, setModel] = useState(null)

  useEffect(() => {
    new FBXLoader().load(
      url,
      object => {
        setModel(object.children[0])
      },
      undefined,
      xhr => console.log(xhr)
    )
  }, [url])

  return model
}

export default useModel
