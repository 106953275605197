import React, { useState, useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'
import ButtonJss from './ButtonJss'

import { useSelector, useDispatch } from 'react-redux'
import { getTotal, setDefaults } from '../../modules/reducerTextures'

import { toggleResumen } from '../../modules/reducerUI'

import IconEuro from './IconEuro'
import IconReplay from './IconReplay'

const useStyles = (theme) =>
  createUseStyles({
    wrapper: {
      height: (props) => props.height,
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      fontSize: '1.2rem'
    },
    linea: {
      backgroundColor: 'black',
      height: 1,
      marginTop: 30,
      width: '100%'
    },
    wrapperSubtotal: { paddingTop: 30 },
    subtotal: { paddingLeft: 5 },
    wrapperTitleTotal: { paddingTop: 20 },
    titleTotal: { margin: 0 },
    iva: { fontSize: '0.8em' },
    total: { paddingLeft: '1em', fontSize: '1.2em' },
    wrapperResumen: { marginTop: 20 },
    ...ButtonJss({
      className: 'button',
      opciones: { backgroundColor: theme.colorPrincipal }
    }),
    ...ButtonJss({
      className: 'buttonReset',
      opciones: { backgroundColor: theme.colorPrincipal, marginLeft: 10 }
    })
  })

const MenuTotales = ({ padding }) => {
  const dispatch = useDispatch()
  const total = useSelector(getTotal)
  const textoIva = '(IVA no incluido)'
  const textoBotonResumen = 'Ver resumen'
  const textoBotonReset = '¿Borrar selección?'

  const dtoggleResumen = useCallback((e) => dispatch(toggleResumen()), [
    dispatch
  ])

  const [solicitudReset, setSolicitudReset] = useState(false)

  const dsetDefaults = useCallback(
    (e) => {
      setSolicitudReset(!solicitudReset)
      dispatch(setDefaults())
    },
    [dispatch, setSolicitudReset, solicitudReset]
  )

  const theme = useTheme()

  let height = '30%'

  const classes = useStyles(theme)({ height })

  return (
    <div
      className={classes.wrapper}
      style={{ padding: `0 ${padding}px ${padding}px ${padding}px` }}
    >
      <div className={classes.linea} />
      <div className={classes.wrapperTitleTotal}>
        <h3 className={classes.titleTotal}>Total</h3>
      </div>
      <div>
        <IconEuro />
        <span className={classes.total}>{total}</span>
      </div>
      <div className={classes.iva}>{textoIva}</div>
      <div className={classes.wrapperResumen}>
        <div className={classes.button} onClick={dtoggleResumen}>
          {textoBotonResumen}
        </div>

        {solicitudReset ? (
          <div className={classes.buttonReset} onClick={dsetDefaults}>
            {textoBotonReset}
          </div>
        ) : (
          <IconReplay
            onClick={(e) => {
              setSolicitudReset(!solicitudReset)
            }}
            size={'2em'}
          />
        )}
      </div>
    </div>
  )
}

export default MenuTotales
