//Actions
export const SET_CAMERA_DATA = 'SET_CAMERA_DATA'
export const SET_TEXTURE_INDEX = 'SET_TEXTURE_INDEX'

const initialState = {
  textures: ['', 'textures/Default_albedo_1.jpg'],
  actualTexture: 0,
  cameraData: {
    position: null,
    target: null
  }
}

export const setCameraData = data => ({
  type: SET_CAMERA_DATA,
  data
})

export const setTextureIndex = textureIndex => ({
  type: SET_TEXTURE_INDEX,
  textureIndex
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMERA_DATA:
      return {
        ...state,
        cameraData: {
          position: action.data.position,
          target: action.data.target
        }
      }
    case SET_TEXTURE_INDEX:
      console.log(action)
      return {
        ...state,
        actualTexture: action.textureIndex
      }
    default:
      return state
  }
}
