//Actions
export const TOGGLE_RESUMEN = 'TOGGLE_RESUMEN'

const initialState = {
  openResumen: false
}

export const toggleResumen = () => ({
  type: TOGGLE_RESUMEN
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_RESUMEN:
      return {
        ...state,
        openResumen: !state.openResumen
      }
    default:
      return state
  }
}
