import * as THREE from 'three'

export const ShaderCube = {
  uniforms: {
    opacity: {
      type: 'f',
      value: 0
    },
    progress: {
      type: 'f',
      value: 0
    },
    initialTexture: {
      type: 't',
      value: null
    },
    initialPosition: {
      type: 'v3',
      value: new THREE.Vector3()
    },
    lastTexture: {
      type: 't',
      value: null
    },
    lastPosition: {
      type: 'v3',
      value: new THREE.Vector3()
    },
    rotXMatrix: {
      type: 'm3',
      value: new THREE.Matrix3()
    },
    rotYMatrix: {
      type: 'm3',
      value: new THREE.Matrix3()
    },
    rotZMatrix: {
      type: 'm3',
      value: new THREE.Matrix3()
    }
  },
  vertexShader: [
    'uniform vec3 initialPosition;',
    'uniform vec3 lastPosition;',
    'uniform mat3 rotXMatrix;',
    'uniform mat3 rotYMatrix;',
    'uniform mat3 rotZMatrix;',

    'varying vec3 initialPositionWorld;',
    'varying vec3 lastPositionWorld;',

    'varying vec2 vUv;',
    'void main() {',
    'vUv = uv;',
    'vec4 worldPosition = modelMatrix * vec4(position, 1.0);',
    'vec3 initialPositionCenter = worldPosition.xyz - initialPosition;',
    'initialPositionWorld = rotYMatrix * rotZMatrix * rotXMatrix * initialPositionCenter;',
    //'initialPositionWorld = (vec4(initialPositionCenter, 1.0) * initialMatrix).xyz;',
    'initialPositionWorld.x *= -1.0;',

    'vec3 lastPositionCenter = worldPosition.xyz - lastPosition;',
    'lastPositionWorld = rotYMatrix * rotZMatrix * rotXMatrix * lastPositionCenter;',
    //'lastPositionWorld = (vec4(lastPositionCenter, 1.0) * lastMatrix).xyz;',
    'lastPositionWorld.x *= -1.0;',
    'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
    '}'
  ].join('\n'),
  fragmentShader: [
    'uniform float opacity;',
    'uniform float progress;',
    'uniform samplerCube initialTexture;',
    'varying vec3 initialPositionWorld;',
    'uniform samplerCube lastTexture;',
    'varying vec3 lastPositionWorld;',
    'void main() {',
    'vec4 initialColor = textureCube(initialTexture, initialPositionWorld.xyz);',
    'vec4 lastColor = textureCube(lastTexture, lastPositionWorld.xyz);',
    'vec4 color = mix(initialColor, lastColor, progress);',
    'gl_FragColor = vec4(color.rgb, opacity);',
    '}'
  ].join('\n')
}

export default ShaderCube
