import React, { useMemo, useRef } from 'react'

import store from '../../../store'
import {
  setMoveModel,
  setDownModel,
  setUpModel
} from '../../../modules/reducer'

import * as THREE from 'three'
import { a } from 'react-spring/three'

import ShaderModel from './ShaderModelCalculateMatrix'
import Rules from '../Rules/Rules'

import useModel from './useModel'
import useChangeCube from './useChangeCube'

//import useCubes from './useCubes'

const normalMatrix = new Map()

const getNormalMatrix = model => {
  if (normalMatrix.has(model.id)) {
    return normalMatrix.get(model.id)
  } else {
    const matrix = new THREE.Matrix3()
    model.updateMatrixWorld(true)

    matrix.getNormalMatrix(model.matrixWorld)
    normalMatrix.set(model.id, matrix)

    return matrix
  }
}

const Model = ({
  url,
  antTexture,
  antPosition,
  actualTexture,
  actualPosition,
  numChanges,
  progress
}) => {
  const model = useModel(url)
  const model1 = useRef(null)

  useChangeCube()

  let actualTexture1 = actualTexture
  let actualPosition1 = actualPosition
  let antTexture1 = antTexture
  let antPosition1 = antPosition

  let opacityLimit = numChanges % 2 === 0 ? 1 : 0

  if (numChanges % 2 !== 0) {
    actualTexture1 = antTexture
    antTexture1 = actualTexture

    actualPosition1 = antPosition
    antPosition1 = actualPosition
  }

  //useCubes()

  const rotXMatrix = useRef(new THREE.Matrix3())
  const rotYMatrix = useRef(new THREE.Matrix3())
  const rotZMatrix = useRef(new THREE.Matrix3())

  useMemo(() => {
    const rotXAngleInRadians = THREE.Math.degToRad(0)
    const rotYAngleInRadians = THREE.Math.degToRad(0)
    const rotZAngleInRadians = THREE.Math.degToRad(0)
    const sy = Math.sin(rotYAngleInRadians)
    const cy = Math.cos(rotYAngleInRadians)
    const sz = Math.sin(rotZAngleInRadians)
    const cz = Math.cos(rotZAngleInRadians)
    const sx = Math.sin(rotXAngleInRadians)
    const cx = Math.cos(rotXAngleInRadians)
    rotYMatrix.current.set(cy, 0, sy, 0, 1, 0, -sy, 0, cy)
    rotZMatrix.current.set(cz, -sz, 0, sz, cz, 0, 0, 0, 1)
    rotXMatrix.current.set(1, 0, 0, 0, cx, -sx, 0, sx, cx)
  }, [])

  return model && actualTexture !== null ? (
    <>
      <Rules model={model1.current} actualPosition={actualPosition} />
      <group
        onPointerDown={e => {
          store.dispatch(setDownModel(e.point))
        }}
        onPointerUp={e => {
          store.dispatch(setUpModel(e.point))
        }}
        onPointerMove={e => {
          store.dispatch(
            setMoveModel({
              point: e.point,
              normal: e.face.normal,
              normalMatrix: getNormalMatrix(e.object)
            })
          )
        }}
      >
        <mesh {...model} ref={model1}>
          <a.shaderMaterial
            attach="material"
            args={[
              {
                uniforms: ShaderModel.uniforms,
                vertexShader: ShaderModel.vertexShader,
                fragmentShader: ShaderModel.fragmentShader,
                side: THREE.FrontSide,
                depthTest: true,
                depthWrite: true,
                fog: false,
                transparent: true
              }
            ]}
            uniforms-opacity-value={progress.interpolate(i => {
              return i === opacityLimit ? 0 : 1
              //return i === 0 ? 0 : i === 1 ? 0 : 1
            })}
            uniforms-progress-value={progress}
            uniforms-initialTexture-value={antTexture1}
            uniforms-initialPosition-value={antPosition1}
            uniforms-lastTexture-value={actualTexture1}
            uniforms-lastPosition-value={actualPosition1}
            uniforms-rotXMatrix-value={rotXMatrix.current}
            uniforms-rotYMatrix-value={rotYMatrix.current}
            uniforms-rotZMatrix-value={rotZMatrix.current}
          />
        </mesh>
      </group>
    </>
  ) : null
}

export default Model
