import * as THREE from 'three'

import { fromEvent, merge } from 'rxjs'
import {
  map,
  takeUntil,
  filter,
  skipWhile,
  startWith,
  scan,
  distinctUntilChanged,
  switchMap,
  pairwise,
  windowToggle,
  mergeAll,
  partition,
  //combineLatest,
  skip
  //tap
} from 'rxjs/operators'

import { store$ } from '../store'

import { getCoors } from './reducer'
import { idActualCubeSelector } from './reducerChanges'
import { opcionesSeleccionadasSelector } from './reducerTextures'
import { nocturnoSelector } from './reducerNocturno'
import { searchId } from './utils'
import settings from './settings'

/*
  const touchStart$ = fromEvent(canvas, 'touchstart').pipe(map(getCoorsTouch))
  const touchMove$ = fromEvent(canvas, 'touchmove').pipe(map(getCoorsTouch))
  const touchEnd$ = fromEvent(canvas, 'touchend').pipe(map(getCoorsTouch))
*/

const getStreamEvent = (event) =>
  store$.pipe(
    map((state) => getCoors(state, event)),
    distinctUntilChanged((prev, act) => prev.x === act.x && prev.y === act.y),
    skip(1)
  )

const mouseDown$ = getStreamEvent('down')
const mouseMove$ = getStreamEvent('move')
const mouseUp$ = getStreamEvent('up')

const start$ = mouseDown$ //merge(mouseDown$, touchStart$) //mouseDown$.pipe(merge(touchStart$))
const move$ = mouseMove$ //merge(mouseMove$, touchMove$) //mouseMove$.pipe(merge(touchMove$))
const end$ = mouseUp$ //merge(mouseUp$, touchEnd$) //mouseUp$.pipe(merge(touchEnd$))

const keyDown$ = fromEvent(window, 'keydown')

const rotateKey$ = merge(
  keyDown$.pipe(
    filter((keyDown) => keyDown.keyCode === 37),
    map((keyDown) => [
      { incLon: 0, incLat: 0 },
      { incLon: -settings['rotation velocity'], incLat: 0 }
    ])
  ),
  keyDown$.pipe(
    filter((keyDown) => keyDown.keyCode === 39),
    map((keyDown) => [
      { incLon: 0, incLat: 0 },
      { incLon: settings['rotation velocity'], incLat: 0 }
    ])
  )
)

const rotateMouse$ = start$.pipe(
  switchMap((start) =>
    move$.pipe(
      skipWhile(
        (move) =>
          Math.abs(start.x - move.x) <= 0.5 && Math.abs(start.y - move.y) <= 0.5
      ),
      startWith(start),
      map((move) => ({
        incLon: (start.x - move.x) * 0.1,
        incLat: (move.y - start.y) * 0.1
      })),
      pairwise(),
      takeUntil(end$)
    )
  )
)

export const rotate$ = merge(rotateKey$, rotateMouse$).pipe(
  scan(
    (result, pair) => ({
      lon: pair[1].incLon + result.lon - pair[0].incLon,
      lat: Math.max(
        -85,
        Math.min(85, pair[1].incLat + result.lat - pair[0].incLat)
      )
    }),
    { lon: 0, lat: 0 }
  )
)

const getStreamModel = (event) =>
  store$.pipe(
    map((state) => ({
      point: state.reducer.coorsModel[event],
      idActualCube: idActualCubeSelector(state)
    })),
    distinctUntilChanged((prev, act) => {
      return (
        prev.point.x === act.point.x &&
        prev.point.y === act.point.y &&
        prev.point.z === act.point.z
      )
    }),
    skip(1)
  )

const downModel$ = getStreamModel('down')
const upModel$ = getStreamModel('up')
const moveModel$ = store$.pipe(
  map((state) => {
    return {
      point: state.reducer.coorsModel.move,
      ...state.reducer.dataMoveModel
    }
  }),
  distinctUntilChanged((prev, act) => {
    return (
      prev.point.x === act.point.x &&
      prev.point.y === act.point.y &&
      prev.point.z === act.point.z
    )
  }),
  skip(1)
)

const clickModel$ = downModel$.pipe(
  switchMap((start) =>
    upModel$.pipe(
      filter(
        (end) =>
          Math.abs(start.point.x - end.point.x) <= 0.5 &&
          Math.abs(start.point.y - end.point.y) <= 0.5 &&
          Math.abs(start.point.z - end.point.z) <= 0.5
      )
    )
  )
)

export const moveModelHelper$ = moveModel$

const isMeasuring$ = store$.pipe(
  map((state) => state.reducer.measuring),
  distinctUntilChanged(),
  partition((v) => !!v)
)

export const clickModelMeasuring$ = clickModel$.pipe(
  windowToggle(isMeasuring$[0], (v) => isMeasuring$[1]),
  mergeAll(),
  scan((acc, point) => {
    return {
      start: !acc.start,
      point: point.point
    }
  }, {})
)

export const moveModelMeasuring$ = moveModel$.pipe(
  windowToggle(isMeasuring$[0], (v) => isMeasuring$[1]),
  mergeAll()
)

const clickModelChangeCube$ = merge(
  clickModel$.pipe(
    takeUntil(isMeasuring$[0]),
    filter((v, index) => index % 2 === 0)
  ),
  clickModel$.pipe(
    windowToggle(isMeasuring$[1], (v) => isMeasuring$[0]),
    mergeAll()
  )
)

const v = new THREE.Vector3()
//const dir = new THREE.Vector3()

/*const nearestCube = (point, model, raycaster) => {
  let idCube = ''
  let d = Infinity
  console.log('adios')
  console.log(point)
  settings.positions.forEach(element => {
    v.fromArray(element.position)
    const distance = point.distanceToSquared(v)

    if (distance < d) {
      dir.subVectors(v, point).normalize()
      raycaster.set(point, dir)
      const inters = raycaster.intersectObject(model, true)

      if (inters.length === 0) {
        idCube = element.id
        d = distance
      }
    }
  })

  return idCube
}

const nearestCubeFrustum = (point, frustum) => {
  let idCube = ''
  let d = Infinity

  settings.positions.forEach(element => {
    v.fromArray(element.position)
    const distance = point.distanceToSquared(v)

    if (distance < d) {
      if (frustum.containsPoint(v)) {
        idCube = element.id
        d = distance
      }
    }
  })

  return idCube
}*/

const nearestCube1 = ({ point, idActualCube }) => {
  let idCube = ''
  let d = Infinity

  //const idActualCube = idActualCubeSelector(store.getState())

  const vecinos = [
    idActualCube,
    ...searchId(settings.positions, idActualCube).vecinos
  ]

  vecinos.forEach((id) => {
    const element = searchId(settings.positions, id)

    v.fromArray(element.position)
    const distance = point.distanceToSquared(v)

    if (distance < d) {
      idCube = element.id
      d = distance
    }
  })

  return idCube
}

export const changeCube$ = clickModelChangeCube$.pipe(
  map((coors) => nearestCube1(coors)),
  //startWith(settings['initial cube']),
  distinctUntilChanged((prev, act) => prev === act)
  //pairwise()
)

/*export const changeCube1$ = (model, raycaster) => {
  return clickModelChangeCube$.pipe(
    map(coors => nearestCube(coors, model, raycaster)),
    startWith(settings['initial cube']),
    distinctUntilChanged((prev, act) => prev === act),
    pairwise()
  )
}*/

const opcionSeleccionada$ = store$.pipe(
  map(opcionesSeleccionadasSelector),
  distinctUntilChanged((prev, act) => {
    return prev === act
  }),
  skip(1)
)

const nocturno$ = store$.pipe(
  map(nocturnoSelector),
  distinctUntilChanged((prev, act) => {
    return prev === act
  }),
  skip(1)
)

export const changeCubeOpcion$ = merge(
  changeCube$.pipe(
    map((cube) => {
      console.log('cube')
      return { cube, opcionesSeleccionadas: '', nocturno: null }
    })
  ),
  opcionSeleccionada$.pipe(
    map((opcionesSeleccionadas) => {
      console.log('opcion')
      return { cube: '', opcionesSeleccionadas, nocturno: null }
    })
  ),
  nocturno$.pipe(
    map((nocturno) => {
      console.log('nocturno')
      return { cube: '', opcionesSeleccionadas: '', nocturno }
    })
  )
).pipe(
  startWith({
    cube: settings['initial cube'],
    opcionesSeleccionadas: JSON.stringify(settings.opcionesSeleccionadas),
    nocturno: false,
    clase: ''
  }),
  scan(
    (result, { cube, opcionesSeleccionadas, nocturno }) => {
      const clase =
        cube === ''
          ? opcionesSeleccionadas === ''
            ? 'nocturno'
            : 'opcion'
          : 'cubes'
      console.log(clase)
      return {
        cube: cube === '' ? result.cube : cube,
        opcionesSeleccionadas:
          opcionesSeleccionadas === ''
            ? result.opcionesSeleccionadas
            : opcionesSeleccionadas,
        nocturno: nocturno === null ? result.nocturno : nocturno,
        clase
      }
    },
    {
      cube: settings['initial cube'],
      opcionesSeleccionadas: JSON.stringify(settings.opcionesSeleccionadas),
      nocturno: false,
      clase: ''
    }
  ),
  pairwise()
)

export const captureImage$ = store$.pipe(
  map((state) => state.reducer.captureImage),
  distinctUntilChanged((prev, act) => prev === act),
  filter((ev) => ev)
)
